<script setup>

import { onMounted, ref } from "vue"
import { useCommunityChatStore } from "@/store/group-chat/community-chat"

const communityChatStore = useCommunityChatStore()

const communityChats = ref([])
const loading = ref(true)

const initial = () => {
  loading.value = true

  communityChatStore.index().then((res) => {
    communityChats.value = res.data.community_chats

    loading.value = false
  })
}

onMounted(() => {
	initial()
})

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Community Chats <Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="$router.push({ name: 'community-chats.create' })" /></h5>
        <DataTable
          class="p-datatable-gridlines"
          dataKey="id"
          filterDisplay="row"
          :lazy="true"
          :loading="loading"
          responsiveLayout="scroll"
          :rowHover="true"
          :value="communityChats"
        >
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column header="Picture">
            <template #body="{data}">
              <Avatar :image="data.picture_group_url" size="xlarge" shape="circle" />
            </template>
          </Column>

          <Column field="title" header="Title" />

          <Column field="can_join" header="Join">
            <template #body="{data}">
              <Chip v-if="data.can_join" label="Yes" icon="pi pi-check" class="custom-chip-success" />
              <Chip v-else label="No" icon="pi pi-times" class="custom-chip-danger" />
            </template>
          </Column>

          <Column field="can_message" header="Message">
            <template #body="{data}">
              <Chip v-if="data.can_message" label="Yes" icon="pi pi-check" class="custom-chip-success" />
              <Chip v-else label="No" icon="pi pi-times" class="custom-chip-danger" />
            </template>
          </Column>

          <Column field="active" header="Active">
            <template #body="{data}">
              <Chip v-if="data.active" label="Yes" icon="pi pi-check" class="custom-chip-success" />
              <Chip v-else label="No" icon="pi pi-times" class="custom-chip-danger" />
            </template>
          </Column>

          <Column header="Provider">
            <template #body="{data}">
              <Chip
                v-for="provider in data.providers"
                :key="provider"
                :label="provider" class="custom-chip-primary px-3"
              />
            </template>
          </Column>

          <Column header="Options">
            <template #body="{data}">
              <router-link :to="`/community-chats/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2 mb-2" />
              </router-link>

              <router-link :to="`/community-chats/${data.id}/edit`">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
              </router-link>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
